<template>
  <!-- 邀请有礼明细 -->
  <!-- v-loading="loading" -->
  <div class="wrap"  id="wrap" ref="wrap">
    <el-form ref="form" :model="couponOptions" :rules="rules" label-width="120px">
      <topOperatingButton
        :showAudit="isShowAudit"
        :isStopBtn="false"
        :isSubmitAddBtn="false"
        :isExamineBtn="![2 , '2'].includes(couponOptions.billStatus)"
        :disabled='disableOperate'
        id="topOperatingButton"
        ref="topOperatingButton"
        @submitForm="submitForm(false)"
        @addBill="submitForm(true)"
        @auditBill="auditForm"
        @getQuit="$router.go(-1)"
      >
      </topOperatingButton>

      <cardTitleCom
        cardTitle="基本信息"
        id="basicInformation"
      >
        <template slot="cardContent">
          <div class="x-f marT10">
            <el-form-item label="活动编号" prop="billNo">
              <el-input
                class="inputWidth"
                size="mini"
                :disabled="true"
                v-model="couponOptions.billNo"
                placeholder="活动编号"
              ></el-input>
            </el-form-item>
            <el-form-item label="活动名称" prop="billName">
              <el-input
                class="inputWidth"
                size="mini"
                :disabled="disableOperate"
                v-model="couponOptions.billName"
                placeholder="活动名称"
                maxlength="40"
              ></el-input>
            </el-form-item>
            <el-form-item label="活动日期" prop="datetime">
              <el-date-picker
                size="mini"
                v-model="couponOptions.datetime"
                type="datetimerange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
                value-format="yyyy-MM-dd HH:mm:ss"
                :disabled="disableOperate"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="方案说明" prop="billRemark">
              <el-input
                size="mini"
                class="inputWidth"
                v-model="couponOptions.billRemark"
                type="textarea"
                placeholder="方案说明长度介于 1 和 200 字符之间"
                maxlength="200"
                :autosize="{ minRows: 1, maxRows: 1 }"
                :disabled="disableOperate"
              />
            </el-form-item>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="活动信息" class="content">
        <template slot="cardContent">
          <div class="giveRule">
            <div class="multiplyTitle">
              <el-form-item label="注册新人奖励"  :required="true">
                <el-form-item label="" prop="">
                  <div class="RuleListdetail">
                    <el-radio :disabled="disableOperate" v-model="couponOptions.newInviteRewardType" :label="0">无奖励</el-radio>
                  </div>
                </el-form-item>
                <el-form-item label="" prop="">
                  <div class="RuleListdetail">
                    <el-radio :disabled="disableOperate" v-model="couponOptions.newInviteRewardType" :label="1">余额</el-radio>
                    <div v-if="['1', 1].includes(couponOptions.newInviteRewardType)"  class="x-f" >
                      <el-form-item label="" prop="">
                        <!-- oninput="value=value.replace(/^0|[^0-9]/g,'')" -->
                        <el-form-item label="" prop="newInviteRewardMoney">
                          <el-input :disabled="disableOperate" size="mini" class="fillRuleinputMoney"
                            v-model="couponOptions.newInviteRewardMoney"
                            oninput="value=value.replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^([0-9-]\d*\.?\d{0,2})?.*$/,'$1').replace(/^\./g, '').replace(/^0+(?!\.|$)/g, ''),value = Number(value) >= 99999 ? 99999 : value"
                            ></el-input>
                            <!-- @input="limitInputlength(couponOptions.newInviteRewardMoney, couponOptions,'newInviteRewardMoney', true, 2, false)" -->
                        </el-form-item>
                      </el-form-item>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="" prop="">
                  <div class="RuleListdetail">
                    <el-radio :disabled="disableOperate" v-model="couponOptions.newInviteRewardType" :label="2">积分</el-radio>
                    <div v-if="['2', 2].includes(couponOptions.newInviteRewardType)"  class="x-f" >
                      <el-form-item label="" prop="newInviteRewardScore">
                        <el-input :disabled="disableOperate" size="mini" class="fillRuleinputMoney"
                          v-model="couponOptions.newInviteRewardScore"
                          oninput="value=value.replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^([0-9-]\d*\.?\d{0,2})?.*$/,'$1').replace(/^\./g, '').replace(/^0+(?!\.|$)/g, ''),value = Number(value) >= 99999 ? 99999 : value"
                          :max="9999999"></el-input>
                          <!-- @input="limitInputlength(couponOptions.newInviteRewardScore, couponOptions,'newInviteRewardScore', true, 2, false)" -->
                      </el-form-item>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="" prop="">
                  <div class="RuleListdetail">
                    <el-radio :disabled="disableOperate" v-model="couponOptions.newInviteRewardType" :label="3">赠送优惠券</el-radio>
                  </div>
                  <div>
                    <el-button :disabled="disableOperate" class="mb10" v-if="['3', 3].includes(couponOptions.newInviteRewardType)" size="mini"
                      @click="handleEvent('openCouponDialog')">选择优惠券</el-button>
                  </div>
                  <div v-if="['3', 3].includes(couponOptions.newInviteRewardType)">
                    <EditTable @handleEvent="handleEvent" :options="couponOptions" />
                  </div>
                </el-form-item>
              </el-form-item>
              <!-- 推荐人奖励 -->
              <el-form-item label="推荐人奖励" :required="true">
                <el-form-item label="" prop="">
                  <div class="RuleListdetail">
                    <el-radio :disabled="disableOperate" v-model="couponOptions.recommendInviteRewardType" :label="0">无奖励</el-radio>
                  </div>
                </el-form-item>
                <el-form-item label="" prop="">
                  <div class="RuleListdetail">
                    <el-radio :disabled="disableOperate" v-model="couponOptions.recommendInviteRewardType" :label="1">余额</el-radio>
                    <div v-if="['1', 1].includes(couponOptions.recommendInviteRewardType)"  class="x-f" >
                      <el-form-item label="" prop="">
                        <el-form-item label="" prop="recommendInviteRewardMoney">
                          <el-input :disabled="disableOperate" size="mini" class="fillRuleinputMoney"
                            v-model="couponOptions.recommendInviteRewardMoney"
                            oninput="value=value.replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^([0-9-]\d*\.?\d{0,2})?.*$/,'$1').replace(/^\./g, '').replace(/^0+(?!\.|$)/g, ''),value = Number(value) >= 99999 ? 99999 : value"
                            :max="9999999"></el-input>
                            <!-- @input="limitInputlength(couponOptions.recommendInviteRewardMoney, couponOptions,'recommendInviteRewardMoney', true, 2, false)" -->
                        </el-form-item>
                      </el-form-item>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="" prop="">
                  <div class="RuleListdetail">
                    <el-radio :disabled="disableOperate" v-model="couponOptions.recommendInviteRewardType" :label="2">积分</el-radio>
                    <div v-if="['2', 2].includes(couponOptions.recommendInviteRewardType)"  class="x-f" >
                      <el-form-item label="" prop="recommendInviteRewardScore">
                        <el-input :disabled="disableOperate" size="mini" class="fillRuleinputMoney"
                          v-model="couponOptions.recommendInviteRewardScore"
                          oninput="value=value.replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^([0-9-]\d*\.?\d{0,2})?.*$/,'$1').replace(/^\./g, '').replace(/^0+(?!\.|$)/g, ''),value = Number(value) >= 99999 ? 99999 : value"
                          :max="9999999"></el-input>
                          <!-- @input="limitInputlength(couponOptions.recommendInviteRewardScore, couponOptions,'recommendInviteRewardScore', true, 2, false)" -->
                      </el-form-item>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="" prop="">
                  <div class="RuleListdetail">
                    <el-radio :disabled="disableOperate" v-model="couponOptions.recommendInviteRewardType" :label="3">赠送优惠券</el-radio>
                  </div>
                  <div>
                    <el-button  :disabled="disableOperate" class="mb10" v-if="['3', 3].includes(couponOptions.recommendInviteRewardType)" size="mini"
                      @click="handleEvent('openCouponDataDialog')">选择优惠券</el-button>
                  </div>
                  <div v-if="['3', 3].includes(couponOptions.recommendInviteRewardType)">
                    <el-form ref="formData" :model="couponOptionsData" :rules="rules" label-width="106px">
                      <EditTable @handleEvent="handleEvent" :options="couponOptionsData" />
                    </el-form>
                  </div>
                </el-form-item>
              </el-form-item>
              <el-form-item label="发放规则" :required="true">
                  <div class="RuleListdetail">
                    <el-radio :disabled="disableOperate" v-model="couponOptions.emitRuleType" :label="0">新人注册后</el-radio>
                    <el-radio :disabled="disableOperate" v-model="couponOptions.emitRuleType" :label="1">新人下单后</el-radio>
                  </div>
              </el-form-item>
              <el-form-item label="奖励限制" >
                  <div class="RuleListdetail">
                    <span class="checkboxTitle">针对余额或积分奖励,每日发放奖励上限人数为</span>
                    <el-input
                      size="mini"
                      @input="limitInputlength(couponOptions.rewardLimitCount, couponOptions,'rewardLimitCount', true, 2, false)"
                      :disabled="disableOperate"
                      class="fillRuleinputMoney"
                      v-model="couponOptions.rewardLimitCount"
                     :max="9999999"></el-input>
                     <el-tooltip style="color: black;margin-left: 10px;" content="不填写是不控制人数" placement="top">
                      <i class="el-icon-question"></i>
                    </el-tooltip>
                  </div>
              </el-form-item>
            </div>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="拓展设置">
        <template slot="cardContent">
          <div class="giveRule">
            <div class="multiplyTitle">
              <el-form-item label="活动分享卡文案" >
                  <div class="RuleListdetail">
                    <el-input
                      size="mini"
                      class="inputWidth"
                      v-model="couponOptions.inviteGiftRecordActivitiesTitle"
                      type="textarea"
                      placeholder="请输入文案"
                      maxlength="200"
                      :autosize="{ minRows: 1, maxRows: 1 }"
                      :disabled="disableOperate"
                    />
                  </div>
                  <div class="text">不填写则展示默认文案"快来试试手气! 点下就能领到手, 速抢!"</div>
              </el-form-item>
              <el-form-item label="活动海报" :required="true">
                  <div class="">
                    <AccessoryUpload listType="picture-card" size="mini" title="" :limit="1" :fileList="fileList"
                                @getFileItems="getFileItems" @delFileItems="deleteImg" :noneBtnImg="couponOptions.posterImageUrl != ''"></AccessoryUpload>
                  </div>
              </el-form-item>
              <el-form-item label="退款设置:" >
                <div class="RuleListdetail">
                  <el-checkbox :disabled="disableOperate" v-model="couponOptions.isOrderRefundCouponInvalid">订单退款时优惠券失效</el-checkbox>
                  <span class="ml10">勾选后，若分享者的订单退款，所有通过该订单分享出来的优惠券将不可继续领取已领取未使用的优惠券也将失效。</span>
                </div>
              </el-form-item>
            </div>
          </div>
        </template>
      </cardTitleCom>
      <Dialog :options.sync="dialogOptions" @handleEvent="handleEvent" />
    </el-form>
    
  </div>
</template>

<script>
import topOperatingButton from '@/views/components/topOperatingButton' //顶部按钮
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import EditTable from '@/components/editTable' //可编辑表格
import Dialog from '@/components/Dialog'
import { uniqWith } from 'lodash' //去重
import { getBillNo } from '@/api/codeRule'
import AccessoryUpload from '@/views/components/accessoryUpload/index.vue'
import { limitInputlength, fcount } from '@/utils'
import {
  inviteGiftSave,
  inviteGiftDetail,
  inviteGiftUpdate,
  inviteGiftAudit,
} from '@/api/O2OMall/marketing/inviteGift'

export default {
  name: 'InviteGiftDetail',
  components: {
    topOperatingButton,
    cardTitleCom,
    EditTable,
    Dialog,
    AccessoryUpload,
  },
  watch: {
    'couponOptions.billStatus': {
      handler (val) {
          this.disableOperate = ['2', '3', '4', 2, 3, 4].includes(val)
          this.isShowAudit = ['', 4, '4'].includes(val)
          this.couponOptions.columns[4].disabled = this.disableOperate
          this.couponOptionsData.columns[4].disabled = this.disableOperate
          this.couponOptions.status = this.disableOperate
      },
      immediate: true
    },
  },
  data () {
    return {
      checked: true,
      fileList: [],
      loading: false,
      radio: '1',
      clickItem: 0, //当前焦点下标
      disableOperate: false,
      isShowAudit: false,
      //弹窗配置
      dialogOptions: {
        curType: '', //当前类型(门店/会员/优惠券)
        title: '选择会员',
        width: 1250,
        show: false,
        type: 'TreeAndTable'
      },
      rules: {
        billNo: [
          {
            required: true,
            message: '请输入方案编号',
            trigger: ['blur', 'change']
          }
        ],
        billName: [
          { required: true, message: '请输入活动名称', trigger: 'blur' }
        ],
        newInviteRewardMoney: [
          { required: true, message: '请输入余额', trigger: 'blur' }
        ],
        newInviteRewardScore: [
          { required: true, message: '请输入积分', trigger: 'blur' }
        ],
        recommendInviteRewardMoney: [
          { required: true, message: '请输入余额', trigger: 'blur' }
        ],
        recommendInviteRewardScore: [
          { required: true, message: '请输入积分', trigger: 'blur' }
        ],
        // newInviteRewardMoney: [
        //   { required: true, message: '请输入活动名称', trigger: 'blur' }
        // ],
        datetime: [
          { required: true, message: '请选择活动日期', trigger: ['blur', 'change'] }
        ],
        // billDate: [
        //   { required: true, message: '请输入制单日期', trigger: 'blur' }
        // ]
      },
      couponOptions: {
        fileItems: [],
        posterImageUrl: '',
        inviteGiftRecordActivitiesTitle: undefined,
        billNo: undefined,
        billName: undefined,
        datetime: undefined,
        remark: undefined,
        status: undefined,
        
        rewardLimitCount: undefined,
        isOrderRefundCouponInvalid: true,
        newInviteRewardType: 0,
        emitRuleType: 0,
        newInviteRewardMoney: undefined, // 余额
        newInviteRewardScore: undefined, // 积分
        o2omallMarketingInviteGiftNewCouponAddReqList: undefined, // 优惠券
        
        recommendInviteRewardType: 0, // 余额
        recommendInviteRewardMoney: undefined, // 余额
        recommendInviteRewardScore: undefined, // 余额
        o2omallMarketingInviteGiftRecommendCouponAddReqList: undefined, // 优惠券

        billStatus: '0',
        tableIndex: 0,
        retainColumn: true,
        curListIndex: -1, //当前表格行
        mutiSelect: false, //是否多选
        list: [],
        curListIndex: -1, //当前表格下标
        tableCellLabel: '序号',
        // tableHeight: 200, //表格高度
        status: undefined, //单据状态
        isNoAddBtn: true, //不显示表格增加
        hideTotal: true,
        columns: [
          {
            prop: 'couponCaseNo',
            label: '优惠券编号',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'couponCaseName',
            label: '优惠券名称',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'couponEmitModeName',
            label: '赠送方式',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'couponEffectModeName',
            label: '生效方式',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'couponEveryEmitQty',
            label: '数量',
            minWidth: 120,
            align: 'center',
            type: 'input',
            disabled: false,
            rules: true,
            isMinus: true,
          },
        ]
      },
      couponOptionsData: {
        fileItems: [],
        billNo: undefined,
        billDate: undefined,
        newInviteRewardType: 0,
        newInviteRewardMoney: undefined, // 余额
        newInviteRewardScore: undefined, // 积分
        o2omallMarketingInviteGiftNewCouponAddReqList: undefined, // 优惠券
        
        recommendInviteRewardType: 0, // 余额
        recommendInviteRewardMoney: undefined, // 余额
        recommendInviteRewardScore: undefined, // 余额
        o2omallMarketingInviteGiftRecommendCouponAddReqList: undefined, // 优惠券
      
        billStatus: '0',
        tableIndex: 0,
        retainColumn: true,
        curListIndex: -1, //当前表格行
        mutiSelect: false, //是否多选
        list: [],
        curListIndex: -1, //当前表格下标
        tableCellLabel: '序号',
        // tableHeight: 200, //表格高度
        status: undefined, //单据状态
        isNoAddBtn: true, //不显示表格增加
        hideTotal: true,
        columns: [
          {
            prop: 'couponCaseNo',
            label: '优惠券编号',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'couponCaseName',
            label: '优惠券名称',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'couponEmitModeName',
            label: '赠送方式',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'couponEffectModeName',
            label: '生效方式',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'couponEveryEmitQty',
            label: '数量',
            minWidth: 120,
            align: 'center',
            type: 'input',
            disabled: false,
            rules: true,
            isMinus: true,
          },
        ]
      },
      form: {
        goodsUrl: undefined,
        couponLargessItem: [{}]
      },
      //图片上传网址
      uploadUrl: process.env.VUE_APP_BASE_API + '/api/open/oss/file/upload'
    }
  },
  methods: {
    limitInputlength,
    getFileItems(file){
      this.couponOptions.posterImageUrl = file.accessUrl
    },
    // 删除LOGO
    deleteImg(){
      this.couponOptions.posterImageUrl = ''
    },
    async getDetail () {
      // this.reset()
      if (this.$route.query.type === 'Update' && this.$route.query.billId) {
        this.loading = true
        try {
          const { data } = await inviteGiftDetail({billId: this.$route.query.billId})
          //方案赋值
          this.couponOptions = {
            ...this.couponOptions,
            ...data,
            list: [],
            datetime: [data.begTime, data.endTime],
          }
          if (this.couponOptions.newInviteRewardType == 3) {
            this.couponOptions.list = this.couponOptions.o2omallMarketingInviteGiftNewCouponList
            this.couponOptions.list = this.couponOptions.list.map((item) => (
                {
                    ...item,
                    couponCaseNo: item.largessCouponCaseNo,
                    couponCaseName: item.largessCouponCaseName,
                    couponEmitModeName:item.couponEffectMode == 0? '即时生效': item.couponEffectMode == 1? '延迟生效' : '固定日期生效',
                    couponEffectModeName: item.couponEffectMode == 0? `即时生效， 生效后有效时长${item.couponValidDays}` :
                      item.couponEffectMode == 1? `延迟生效， 领取${item.couponEffectDays}天后生效,生效后有效时长${item.couponValidDays}天` 
                      : `固定日期生效， ${item.couponEffectDate},生效后有效时长${item.couponValidDays}天` 
                }
            ))
          }
          if (this.couponOptions.recommendInviteRewardType == 3) {
            this.couponOptionsData.list = this.couponOptions.o2omallMarketingInviteGiftRecommendCouponList
            this.couponOptionsData.list = this.couponOptionsData.list.map((item) => (
                {
                    ...item,
                    couponCaseNo: item.largessCouponCaseNo,
                    couponCaseName: item.largessCouponCaseName,
                    couponEmitModeName:item.couponEffectMode == 0? '即时生效': item.couponEffectMode == 1? '延迟生效' : '固定日期生效',
                    couponEffectModeName: item.couponEffectMode == 0? `即时生效， 生效后有效时长${item.couponValidDays}` :
                      item.couponEffectMode == 1? `延迟生效， 领取${item.couponEffectDays}天后生效,生效后有效时长${item.couponValidDays}天` 
                      : `固定日期生效， ${item.couponEffectDate},生效后有效时长${item.couponValidDays}天` 
                }
            ))
          }
          this.couponOptions.posterImageUrl = this.couponOptions.posterImageUrl? this.couponOptions.posterImageUrl : ''
          this.fileList = this.couponOptions.posterImageUrl? [{url :this.couponOptions.posterImageUrl}] : []
        } catch (err) {}
        this.loading = false
      } else {
        this.getBillno()
      }
    },
    // 获取订单编号
    async getBillno () {
      this.couponOptions.billNo = await getBillNo(180206)
    },
    async handleEvent (type, row) {
      switch (type) {
        case 'openCouponDialog':
          //弹窗配置
          this.dialogOptions = {
            curTable: 'couponOptions',
            curType: 'couponCaseId', //当前类型(会员/门店/优惠券)
            title: '选择优惠券',
            width: 1250,
            show: true,
            type: 'TreeAndTable',
            isStep: true,
            isGrant: false,
            isNumber: false,
            formData: this.$dialog({ key: 'coupon' })
          }
          this.dialogOptions.formData.table.radioSelect = true
          this.dialogOptions.formData.table.mutiSelect = false
          break
        case 'openCouponDataDialog':
          //弹窗配置
          this.dialogOptions = {
            curTable: 'couponOptionsData',
            curType: 'couponCaseId', //当前类型(会员/门店/优惠券)
            title: '选择优惠券',
            width: 1250,
            show: true,
            type: 'TreeAndTable',
            isStep: true,
            isGrant: false,
            isNumber: false,
            formData: this.$dialog({ key: 'coupon' })
          }
          this.dialogOptions.formData.table.radioSelect = true
          this.dialogOptions.formData.table.mutiSelect = false
          break
        case 'openTimeDateDialog':
           //弹窗配置
           this.dialogOptions = {
            curTable: 'dateOptions',
            curType: 'timeDate', 
            title: '选择时间日期',
            width: 600,
            show: true,
            type: 'TimeDateSelect',
            formData: {
              DateTimeList: this.dateOptions.echoList // 日期
            }
          }
          break
        case 'dialogChange':
          let list = []
          let curTable = this.dialogOptions.curTable
          let curType = this.dialogOptions.curType
          if(curType != 'timeDate'){
            if (this.dialogOptions.formData.table.check.length <= 0) return
            list = this.dialogOptions.formData.table.check
            list = [...list, ...this[curTable].list]
            list = uniqWith(list, (x, y) => x[curType] == y[curType])
            if (curType == 'couponCaseId')
              list = list.map(item => ({
                ...item,
                largessCouponCaseId: item.couponCaseId
            }))
          }else{
            this[curTable].echoList = this.dialogOptions.formData.DateTimeList //方便适用时间回显
            list = await getDateChangeDetail(
              this.dialogOptions.formData.DateTimeList  || []
            )
          }
          this[curTable].list = list
          break
        default:
          break
      }
    },
    async submitForm () {
      if (this.couponOptions.newInviteRewardType == 3) {
        let sourceIndex = this.couponOptions.list.findIndex(item => !item.couponEveryEmitQty )
        if (sourceIndex != '-1' || this.couponOptions.list.length == 0) return this.$message.error('请录入完整的优惠券信息')
      }
      if (this.couponOptions.recommendInviteRewardType == 3) {
        let sourceIndex = this.couponOptionsData.list.findIndex(item => !item.couponEveryEmitQty )
        if (sourceIndex != '-1' || this.couponOptionsData.list.length == 0) return this.$message.error('请录入完整的优惠券信息')
      }
      try {
        const valid = await this.$refs.form.validate()
        this.loading = true
        let form = {
          billNo: this.couponOptions.billNo,
          billId: this.couponOptions.billId,
          billName: this.couponOptions.billName,
          begTime: this.couponOptions.datetime[0],
          endTime: this.couponOptions.datetime[1],
          billRemark: this.couponOptions.billRemark,
          newInviteRewardType: this.couponOptions.newInviteRewardType,
          newInviteRewardMoney: this.couponOptions.newInviteRewardMoney,
          newInviteRewardScore: this.couponOptions.newInviteRewardScore,

          recommendInviteRewardType: this.couponOptions.recommendInviteRewardType,
          recommendInviteRewardMoney: this.couponOptions.recommendInviteRewardMoney,
          recommendInviteRewardScore: this.couponOptions.recommendInviteRewardScore,

          emitRuleType: this.couponOptions.emitRuleType,
          rewardLimitCount: this.couponOptions.rewardLimitCount,
          isOrderRefundCouponInvalid: this.couponOptions.isOrderRefundCouponInvalid,
          
          o2omallMarketingInviteGiftNewCouponAddReqList: this.couponOptions.list,
          o2omallMarketingInviteGiftRecommendCouponAddReqList: this.couponOptionsData.list,
          posterImageUrl: this.couponOptions.posterImageUrl,
          inviteGiftRecordActivitiesTitle: this.couponOptions.inviteGiftRecordActivitiesTitle,
        }
        if (!this.couponOptions.posterImageUrl) return this.$message.warning('请上传活动海报')
        if (form.billId) {
          inviteGiftUpdate(form).then((res) => {
            this.$message.success('修改成功')
            this.loading = false
            return true
          }).catch((err) => {
            this.loading = false
            return false
          })
        } else {
          inviteGiftSave(form).then((res) => {
            this.$message.success('新增成功')
            this.couponOptions.billId = res.data
            this.loading = false
            return true
          }).catch((err) => {
            this.loading = false
            return false
          })
        }
      } catch (error) {
        this.$message.warning('请输入必填项')
        return false
      }
    },
    async auditForm (type) {
      // let isSub = await this.submitForm()
      // if (!isSub) return
      if ([2, '2'].includes(this.couponOptions.billStatus) && type == '审核') return this.$message.warning('此单据不是未审核的状态哦~')
      if (['审核', '反审核'].includes(type)) {
        await this.$confirm(`确定${type}该方案`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        this.loading = true
        const { data } = await inviteGiftAudit({
          billIds: [this.couponOptions.billId],
          auditStatus: type == '审核'? 2 : 0
        })
        this.couponOptions.billStatus = 2
        this.$message.success(`${type}成功`)
        this.loading = false
      }
    },
  },
  activated () {
    this.getDetail()
  },
  mounted () {
    this.getDetail()
  },
}
</script>

<style lang="scss" scoped>
.wrap {
  padding: 40px 10px 10px 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  overflow-y: scroll;
  //附件
  .accessoryUpload {
    margin: 6px 10px;
    min-height: 150px;
    width: 200px;
    height: 200px;
  }
  .el-upload--picture-card{
    width: 200px;
    height: 200px;
  }
  ::v-deep .el-upload--picture-card {
    width: 100px;
    height: 100px;
  }
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 250px;
}
.table {
  padding: 10px;
}
.leftContent {
  .text {
    width: 70px;
    font-size: 14px;
    line-height: 40px;
  }
  .el-icon-question {
    margin-top: 10px;
  }
  ::v-deep .el-input--medium {
    width: 140px;
  }
  ::v-deep .el-input__inner {
    border-radius: 0;
    border: none;
    border-bottom: 2px solid #ccc;
  }
}
  //充值规则
.giveRule {
  padding: 0 20px;
  .multiplyTitle {
    padding: 20px 0 10px 0;
    .checkboxTitle {
      margin-left: 7px;
      font-size: 14px;
    }
  }
  //充值规则列表
  .fillRuleListTable {
    display: flex;
    cursor: pointer;
    .fillRuleList {
      padding: 7px;
      width: 200px;
      height: 120px;
      font-size: 14px;
      margin-right: 24px;
      visibility: visible;
      border: 1px solid #e6e6e6;
      .fillRuleListAdd {
        color: #2386ee;
        display: flex;
        flex-direction: row;
        line-height: 110px;
        align-items: center;
        justify-content: center;
      }
      //删除图标
      .fillRuleListMoney {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    //充值规则列表获得焦点
    .focusfillRuleList {
      position: relative;
      padding: 7px;
      width: 200px;
      height: 120px;
      font-size: 14px;
      margin-right: 24px;
      visibility: visible;
      border: 1px solid #017ee1;

      &::before {
        position: absolute;
        content: '';
        right: 0;
        bottom: 0;
        border-right: 13px solid #2386ee;
        border-left: 13px solid transparent;
        border-top: 11px solid transparent;
        border-bottom: 11px solid #2386ee;
      }
      &::after {
        position: absolute;
        content: '';
        width: 10px;
        height: 6px;
        background-color: transparent;
        right: 3px;
        bottom: 5px;
        border-left: 2px solid #fff;
        border-bottom: 2px solid #fff;
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
      .fillRuleListMoney {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  //充值规则详情
  .RuleListdetail {
    display: flex;
    align-items: center;
    margin: 5px 0 0px 10px;
    height: 28px;
    ::v-deep .el-form-item {
      margin-bottom: 0;
    }
    //指定商品
    .appointGoods {
      height: 28px;
      line-height: 28px;
      margin-left: 20px;
    }
    //赠送金额多选框
    .giveMoneyRadio {
      margin: 0 20px;
    }
    //多选框
    .el-checkbox {
      margin-right: 0;
    }
    span {
      font-size: 14px;
    }
    //充值规则详情
    .fillRuleinput {
      width: 150px;
      margin-left: 20px;
    }
    //赠送金额
    .fillRuleinputMoney {
      width: 150px;
      margin-right: 10px;
      margin-left: 10px;
    }

    //详情标题
    .checkboxTitle {
      margin-left: 7px;
      margin-right: 5px;
    }
  }
}
.ml10 {
  margin-left: 20px;
}
.mb10 {
  margin-bottom: 10px !important;
}
.contnet {
  .el-form-item{
    margin-bottom: 10px;
  } 
}

</style>
