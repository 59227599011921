<template>
  <!-- 商城链接推广 -->
  <div class="linkAd">
    <el-card class="box-card">
      <!-- 头部区域 -->
      <div class="header">
        <!-- 搜索区域 -->
        <searchTitle :isHighSearch="true">
          <template #selectTop>
            <el-form-item label-width="0px">
              <el-input
                class="searchInput"
                placeholder="请搜索活动编号/活动名称"
                prefix-icon="el-icon-search"
                v-model="selectVal"
                size="small"
              ></el-input>
            </el-form-item>
          </template>
        </searchTitle>
        <!-- 按钮区域 -->
        <comBtn
          :isAddBtn="false"
          :isAuditBtn="false"
          :isStartUsingBtn="false"
          :isCopyBtn="false"
          :isDelBtn="false"
          :isRefreshBtn="false"
        >
          <template #specialDiyBtn>
            <el-button
              class="blue-btn"
              icon="el-icon-picture-outline"
              plain
              size="mini"
              @click="clickBtn('推广链接')"
              >推广链接</el-button
            >
            <el-button
              class="blue-btn"
              icon="el-icon-picture-outline"
              plain
              size="mini"
              @click="clickBtn('复制小程序路径')"
              >复制小程序路径</el-button
            >
          </template>
        </comBtn>
      </div>
      <!-- 表格区域 -->
      <div class="table">
        <!-- 表格数据 -->
        <el-table
          v-loading="loading"
          :data="seckillList"
          @selection-change="handleSelectionChange"
          border
          height="605"
        >
          <el-table-column
            type="selection"
            width="50"
            align="center"
            fixed="left"
          />
          <el-table-column
            type="index"
            width="50"
            align="center"
            label="序号"
            fixed="left"
          />
          <el-table-column
            label="图片"
            align="center"
            key="remark"
            prop="categoryName"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="商品编码"
            align="center"
            key="categoryNo"
            prop="categoryNo"
          >
            <template slot-scope="scope">
              <el-button type="text">{{ scope.row.categoryNo }}</el-button>
            </template>
          </el-table-column>
          <el-table-column
            label="商品名称"
            align="center"
            key="categoryName"
            prop="categoryName"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="商城分类"
            align="center"
            key="remark"
            prop="categoryName"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="品牌"
            align="center"
            key="remark"
            prop="categoryName"
            :show-overflow-tooltip="true"
          />
         
          <el-table-column
            label="售价"
            align="center"
            key="remark"
            prop="categoryName"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="库存"
            align="center"
            key="createBy"
            prop="createBy"
            :show-overflow-tooltip="true"
          />
        </el-table>
        <!-- 分页 -->
        <!-- <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
        />-->
      </div>
      <!-- 推广链接弹出框 -->
      <el-dialog
        title="商品链接推广"
        :visible.sync="openPromotionLink"
        width="50%"
        v-dialogDrag="true"
      >
        <el-form label-width="80px">
          <el-form-item label="推广文案">
            <el-input
              type="textarea"
              placeholder="请输入文案"
              maxlength="300"
              show-word-limit
              :rows="10"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="推广链接">
            <el-input></el-input>
          </el-form-item>
        </el-form>
        <div class="btn">
          <el-button
            type="primary"
            @click="openPromotionLink = false"
            size="mini"
            >复制文案+链接</el-button
          >
          <el-button
            type="primary"
            @click="openPromotionLink = false"
            size="mini"
            >复制链接</el-button
          >
          <el-button @click="openPromotionLink = false" size="mini"
            >取 消</el-button
          >
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import searchTitle from '@/views/components/searchTitle' //搜索区域组件
import comBtn from '@/views/components/com-btn' //按钮区域组件
export default {
  name: 'LinkAd',
  components: {
    searchTitle,
    comBtn
  },
  data () {
    return {
      selectVal: undefined, //搜索框
      loading: false, //遮罩层
      seckillList: [], //团购列表数据
      openPromotionLink: false //推广链接弹出框开关
    }
  },
  methods: {
    // 表格多选框选中数据
    handleSelectionChange (selection) {},
    //点击按钮事件 type参数区分类型(新增/审核/启用/复制/删除/刷新)
    clickBtn (type) {
      console.log(type)
      if (type === '新增') {
        this.$router.push({
          name: 'SeckillDetail'
        })
      }else if(type === '推广链接'){
        this.openPromotionLink = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.linkAd {
  padding: 10px;
  background-color: #f3f3f3;
  .el-card {
    height: 820px;
    //搜索框区域
    .searchInput {
      width: 320px;
    }
    //推广弹出框
    .el-dialog {
      .btn {
        text-align: right;
      }
    }
  }
}
</style>
