var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "linkAd" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            { staticClass: "header" },
            [
              _c("searchTitle", {
                attrs: { isHighSearch: true },
                scopedSlots: _vm._u([
                  {
                    key: "selectTop",
                    fn: function () {
                      return [
                        _c(
                          "el-form-item",
                          { attrs: { "label-width": "0px" } },
                          [
                            _c("el-input", {
                              staticClass: "searchInput",
                              attrs: {
                                placeholder: "请搜索活动编号/活动名称",
                                "prefix-icon": "el-icon-search",
                                size: "small",
                              },
                              model: {
                                value: _vm.selectVal,
                                callback: function ($$v) {
                                  _vm.selectVal = $$v
                                },
                                expression: "selectVal",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("comBtn", {
                attrs: {
                  isAddBtn: false,
                  isAuditBtn: false,
                  isStartUsingBtn: false,
                  isCopyBtn: false,
                  isDelBtn: false,
                  isRefreshBtn: false,
                },
                scopedSlots: _vm._u([
                  {
                    key: "specialDiyBtn",
                    fn: function () {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "blue-btn",
                            attrs: {
                              icon: "el-icon-picture-outline",
                              plain: "",
                              size: "mini",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.clickBtn("推广链接")
                              },
                            },
                          },
                          [_vm._v("推广链接")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "blue-btn",
                            attrs: {
                              icon: "el-icon-picture-outline",
                              plain: "",
                              size: "mini",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.clickBtn("复制小程序路径")
                              },
                            },
                          },
                          [_vm._v("复制小程序路径")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "table" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  attrs: { data: _vm.seckillList, border: "", height: "605" },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "selection",
                      width: "50",
                      align: "center",
                      fixed: "left",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      width: "50",
                      align: "center",
                      label: "序号",
                      fixed: "left",
                    },
                  }),
                  _c("el-table-column", {
                    key: "remark",
                    attrs: {
                      label: "图片",
                      align: "center",
                      prop: "categoryName",
                      "show-overflow-tooltip": true,
                    },
                  }),
                  _c("el-table-column", {
                    key: "categoryNo",
                    attrs: {
                      label: "商品编码",
                      align: "center",
                      prop: "categoryNo",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-button", { attrs: { type: "text" } }, [
                              _vm._v(_vm._s(scope.row.categoryNo)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    key: "categoryName",
                    attrs: {
                      label: "商品名称",
                      align: "center",
                      prop: "categoryName",
                      "show-overflow-tooltip": true,
                    },
                  }),
                  _c("el-table-column", {
                    key: "remark",
                    attrs: {
                      label: "商城分类",
                      align: "center",
                      prop: "categoryName",
                      "show-overflow-tooltip": true,
                    },
                  }),
                  _c("el-table-column", {
                    key: "remark",
                    attrs: {
                      label: "品牌",
                      align: "center",
                      prop: "categoryName",
                      "show-overflow-tooltip": true,
                    },
                  }),
                  _c("el-table-column", {
                    key: "remark",
                    attrs: {
                      label: "售价",
                      align: "center",
                      prop: "categoryName",
                      "show-overflow-tooltip": true,
                    },
                  }),
                  _c("el-table-column", {
                    key: "createBy",
                    attrs: {
                      label: "库存",
                      align: "center",
                      prop: "createBy",
                      "show-overflow-tooltip": true,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              directives: [
                {
                  name: "dialogDrag",
                  rawName: "v-dialogDrag",
                  value: true,
                  expression: "true",
                },
              ],
              attrs: {
                title: "商品链接推广",
                visible: _vm.openPromotionLink,
                width: "50%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.openPromotionLink = $event
                },
              },
            },
            [
              _c(
                "el-form",
                { attrs: { "label-width": "80px" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "推广文案" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入文案",
                          maxlength: "300",
                          "show-word-limit": "",
                          rows: 10,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "推广链接" } },
                    [_c("el-input")],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "btn" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: {
                        click: function ($event) {
                          _vm.openPromotionLink = false
                        },
                      },
                    },
                    [_vm._v("复制文案+链接")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: {
                        click: function ($event) {
                          _vm.openPromotionLink = false
                        },
                      },
                    },
                    [_vm._v("复制链接")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          _vm.openPromotionLink = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }