var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "wrap", staticClass: "wrap", attrs: { id: "wrap" } },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.couponOptions,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c("topOperatingButton", {
            ref: "topOperatingButton",
            attrs: {
              showAudit: _vm.isShowAudit,
              isStopBtn: false,
              isSubmitAddBtn: false,
              isExamineBtn: ![2, "2"].includes(_vm.couponOptions.billStatus),
              disabled: _vm.disableOperate,
              id: "topOperatingButton",
            },
            on: {
              submitForm: function ($event) {
                return _vm.submitForm(false)
              },
              addBill: function ($event) {
                return _vm.submitForm(true)
              },
              auditBill: _vm.auditForm,
              getQuit: function ($event) {
                return _vm.$router.go(-1)
              },
            },
          }),
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "基本信息", id: "basicInformation" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "x-f marT10" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "活动编号", prop: "billNo" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            size: "mini",
                            disabled: true,
                            placeholder: "活动编号",
                          },
                          model: {
                            value: _vm.couponOptions.billNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.couponOptions, "billNo", $$v)
                            },
                            expression: "couponOptions.billNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "活动名称", prop: "billName" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            size: "mini",
                            disabled: _vm.disableOperate,
                            placeholder: "活动名称",
                            maxlength: "40",
                          },
                          model: {
                            value: _vm.couponOptions.billName,
                            callback: function ($$v) {
                              _vm.$set(_vm.couponOptions, "billName", $$v)
                            },
                            expression: "couponOptions.billName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "活动日期", prop: "datetime" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            size: "mini",
                            type: "datetimerange",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            "default-time": ["00:00:00", "23:59:59"],
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                            disabled: _vm.disableOperate,
                          },
                          model: {
                            value: _vm.couponOptions.datetime,
                            callback: function ($$v) {
                              _vm.$set(_vm.couponOptions, "datetime", $$v)
                            },
                            expression: "couponOptions.datetime",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "方案说明", prop: "billRemark" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            size: "mini",
                            type: "textarea",
                            placeholder: "方案说明长度介于 1 和 200 字符之间",
                            maxlength: "200",
                            autosize: { minRows: 1, maxRows: 1 },
                            disabled: _vm.disableOperate,
                          },
                          model: {
                            value: _vm.couponOptions.billRemark,
                            callback: function ($$v) {
                              _vm.$set(_vm.couponOptions, "billRemark", $$v)
                            },
                            expression: "couponOptions.billRemark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { staticClass: "content", attrs: { cardTitle: "活动信息" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c("div", { staticClass: "giveRule" }, [
                  _c(
                    "div",
                    { staticClass: "multiplyTitle" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "注册新人奖励", required: true } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "", prop: "" } },
                            [
                              _c(
                                "div",
                                { staticClass: "RuleListdetail" },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: {
                                        disabled: _vm.disableOperate,
                                        label: 0,
                                      },
                                      model: {
                                        value:
                                          _vm.couponOptions.newInviteRewardType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.couponOptions,
                                            "newInviteRewardType",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "couponOptions.newInviteRewardType",
                                      },
                                    },
                                    [_vm._v("无奖励")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "", prop: "" } },
                            [
                              _c(
                                "div",
                                { staticClass: "RuleListdetail" },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: {
                                        disabled: _vm.disableOperate,
                                        label: 1,
                                      },
                                      model: {
                                        value:
                                          _vm.couponOptions.newInviteRewardType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.couponOptions,
                                            "newInviteRewardType",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "couponOptions.newInviteRewardType",
                                      },
                                    },
                                    [_vm._v("余额")]
                                  ),
                                  ["1", 1].includes(
                                    _vm.couponOptions.newInviteRewardType
                                  )
                                    ? _c(
                                        "div",
                                        { staticClass: "x-f" },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "", prop: "" } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "",
                                                    prop: "newInviteRewardMoney",
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    staticClass:
                                                      "fillRuleinputMoney",
                                                    attrs: {
                                                      disabled:
                                                        _vm.disableOperate,
                                                      size: "mini",
                                                      oninput:
                                                        "value=value.replace(/\\.{2,}/g, '.').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^([0-9-]\\d*\\.?\\d{0,2})?.*$/,'$1').replace(/^\\./g, '').replace(/^0+(?!\\.|$)/g, ''),value = Number(value) >= 99999 ? 99999 : value",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.couponOptions
                                                          .newInviteRewardMoney,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.couponOptions,
                                                          "newInviteRewardMoney",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "couponOptions.newInviteRewardMoney",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "", prop: "" } },
                            [
                              _c(
                                "div",
                                { staticClass: "RuleListdetail" },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: {
                                        disabled: _vm.disableOperate,
                                        label: 2,
                                      },
                                      model: {
                                        value:
                                          _vm.couponOptions.newInviteRewardType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.couponOptions,
                                            "newInviteRewardType",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "couponOptions.newInviteRewardType",
                                      },
                                    },
                                    [_vm._v("积分")]
                                  ),
                                  ["2", 2].includes(
                                    _vm.couponOptions.newInviteRewardType
                                  )
                                    ? _c(
                                        "div",
                                        { staticClass: "x-f" },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "",
                                                prop: "newInviteRewardScore",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass:
                                                  "fillRuleinputMoney",
                                                attrs: {
                                                  disabled: _vm.disableOperate,
                                                  size: "mini",
                                                  oninput:
                                                    "value=value.replace(/\\.{2,}/g, '.').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^([0-9-]\\d*\\.?\\d{0,2})?.*$/,'$1').replace(/^\\./g, '').replace(/^0+(?!\\.|$)/g, ''),value = Number(value) >= 99999 ? 99999 : value",
                                                  max: 9999999,
                                                },
                                                model: {
                                                  value:
                                                    _vm.couponOptions
                                                      .newInviteRewardScore,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.couponOptions,
                                                      "newInviteRewardScore",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "couponOptions.newInviteRewardScore",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "", prop: "" } },
                            [
                              _c(
                                "div",
                                { staticClass: "RuleListdetail" },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: {
                                        disabled: _vm.disableOperate,
                                        label: 3,
                                      },
                                      model: {
                                        value:
                                          _vm.couponOptions.newInviteRewardType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.couponOptions,
                                            "newInviteRewardType",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "couponOptions.newInviteRewardType",
                                      },
                                    },
                                    [_vm._v("赠送优惠券")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  ["3", 3].includes(
                                    _vm.couponOptions.newInviteRewardType
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass: "mb10",
                                          attrs: {
                                            disabled: _vm.disableOperate,
                                            size: "mini",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleEvent(
                                                "openCouponDialog"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("选择优惠券")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              ["3", 3].includes(
                                _vm.couponOptions.newInviteRewardType
                              )
                                ? _c(
                                    "div",
                                    [
                                      _c("EditTable", {
                                        attrs: { options: _vm.couponOptions },
                                        on: { handleEvent: _vm.handleEvent },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "推荐人奖励", required: true } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "", prop: "" } },
                            [
                              _c(
                                "div",
                                { staticClass: "RuleListdetail" },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: {
                                        disabled: _vm.disableOperate,
                                        label: 0,
                                      },
                                      model: {
                                        value:
                                          _vm.couponOptions
                                            .recommendInviteRewardType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.couponOptions,
                                            "recommendInviteRewardType",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "couponOptions.recommendInviteRewardType",
                                      },
                                    },
                                    [_vm._v("无奖励")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "", prop: "" } },
                            [
                              _c(
                                "div",
                                { staticClass: "RuleListdetail" },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: {
                                        disabled: _vm.disableOperate,
                                        label: 1,
                                      },
                                      model: {
                                        value:
                                          _vm.couponOptions
                                            .recommendInviteRewardType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.couponOptions,
                                            "recommendInviteRewardType",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "couponOptions.recommendInviteRewardType",
                                      },
                                    },
                                    [_vm._v("余额")]
                                  ),
                                  ["1", 1].includes(
                                    _vm.couponOptions.recommendInviteRewardType
                                  )
                                    ? _c(
                                        "div",
                                        { staticClass: "x-f" },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "", prop: "" } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "",
                                                    prop: "recommendInviteRewardMoney",
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    staticClass:
                                                      "fillRuleinputMoney",
                                                    attrs: {
                                                      disabled:
                                                        _vm.disableOperate,
                                                      size: "mini",
                                                      oninput:
                                                        "value=value.replace(/\\.{2,}/g, '.').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^([0-9-]\\d*\\.?\\d{0,2})?.*$/,'$1').replace(/^\\./g, '').replace(/^0+(?!\\.|$)/g, ''),value = Number(value) >= 99999 ? 99999 : value",
                                                      max: 9999999,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.couponOptions
                                                          .recommendInviteRewardMoney,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.couponOptions,
                                                          "recommendInviteRewardMoney",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "couponOptions.recommendInviteRewardMoney",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "", prop: "" } },
                            [
                              _c(
                                "div",
                                { staticClass: "RuleListdetail" },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: {
                                        disabled: _vm.disableOperate,
                                        label: 2,
                                      },
                                      model: {
                                        value:
                                          _vm.couponOptions
                                            .recommendInviteRewardType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.couponOptions,
                                            "recommendInviteRewardType",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "couponOptions.recommendInviteRewardType",
                                      },
                                    },
                                    [_vm._v("积分")]
                                  ),
                                  ["2", 2].includes(
                                    _vm.couponOptions.recommendInviteRewardType
                                  )
                                    ? _c(
                                        "div",
                                        { staticClass: "x-f" },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "",
                                                prop: "recommendInviteRewardScore",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass:
                                                  "fillRuleinputMoney",
                                                attrs: {
                                                  disabled: _vm.disableOperate,
                                                  size: "mini",
                                                  oninput:
                                                    "value=value.replace(/\\.{2,}/g, '.').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^([0-9-]\\d*\\.?\\d{0,2})?.*$/,'$1').replace(/^\\./g, '').replace(/^0+(?!\\.|$)/g, ''),value = Number(value) >= 99999 ? 99999 : value",
                                                  max: 9999999,
                                                },
                                                model: {
                                                  value:
                                                    _vm.couponOptions
                                                      .recommendInviteRewardScore,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.couponOptions,
                                                      "recommendInviteRewardScore",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "couponOptions.recommendInviteRewardScore",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "", prop: "" } },
                            [
                              _c(
                                "div",
                                { staticClass: "RuleListdetail" },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: {
                                        disabled: _vm.disableOperate,
                                        label: 3,
                                      },
                                      model: {
                                        value:
                                          _vm.couponOptions
                                            .recommendInviteRewardType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.couponOptions,
                                            "recommendInviteRewardType",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "couponOptions.recommendInviteRewardType",
                                      },
                                    },
                                    [_vm._v("赠送优惠券")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  ["3", 3].includes(
                                    _vm.couponOptions.recommendInviteRewardType
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass: "mb10",
                                          attrs: {
                                            disabled: _vm.disableOperate,
                                            size: "mini",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleEvent(
                                                "openCouponDataDialog"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("选择优惠券")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              ["3", 3].includes(
                                _vm.couponOptions.recommendInviteRewardType
                              )
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-form",
                                        {
                                          ref: "formData",
                                          attrs: {
                                            model: _vm.couponOptionsData,
                                            rules: _vm.rules,
                                            "label-width": "106px",
                                          },
                                        },
                                        [
                                          _c("EditTable", {
                                            attrs: {
                                              options: _vm.couponOptionsData,
                                            },
                                            on: {
                                              handleEvent: _vm.handleEvent,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "发放规则", required: true } },
                        [
                          _c(
                            "div",
                            { staticClass: "RuleListdetail" },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: {
                                    disabled: _vm.disableOperate,
                                    label: 0,
                                  },
                                  model: {
                                    value: _vm.couponOptions.emitRuleType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.couponOptions,
                                        "emitRuleType",
                                        $$v
                                      )
                                    },
                                    expression: "couponOptions.emitRuleType",
                                  },
                                },
                                [_vm._v("新人注册后")]
                              ),
                              _c(
                                "el-radio",
                                {
                                  attrs: {
                                    disabled: _vm.disableOperate,
                                    label: 1,
                                  },
                                  model: {
                                    value: _vm.couponOptions.emitRuleType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.couponOptions,
                                        "emitRuleType",
                                        $$v
                                      )
                                    },
                                    expression: "couponOptions.emitRuleType",
                                  },
                                },
                                [_vm._v("新人下单后")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c("el-form-item", { attrs: { label: "奖励限制" } }, [
                        _c(
                          "div",
                          { staticClass: "RuleListdetail" },
                          [
                            _c("span", { staticClass: "checkboxTitle" }, [
                              _vm._v(
                                "针对余额或积分奖励,每日发放奖励上限人数为"
                              ),
                            ]),
                            _c("el-input", {
                              staticClass: "fillRuleinputMoney",
                              attrs: {
                                size: "mini",
                                disabled: _vm.disableOperate,
                                max: 9999999,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.limitInputlength(
                                    _vm.couponOptions.rewardLimitCount,
                                    _vm.couponOptions,
                                    "rewardLimitCount",
                                    true,
                                    2,
                                    false
                                  )
                                },
                              },
                              model: {
                                value: _vm.couponOptions.rewardLimitCount,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.couponOptions,
                                    "rewardLimitCount",
                                    $$v
                                  )
                                },
                                expression: "couponOptions.rewardLimitCount",
                              },
                            }),
                            _c(
                              "el-tooltip",
                              {
                                staticStyle: {
                                  color: "black",
                                  "margin-left": "10px",
                                },
                                attrs: {
                                  content: "不填写是不控制人数",
                                  placement: "top",
                                },
                              },
                              [_c("i", { staticClass: "el-icon-question" })]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "拓展设置" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c("div", { staticClass: "giveRule" }, [
                  _c(
                    "div",
                    { staticClass: "multiplyTitle" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "活动分享卡文案" } },
                        [
                          _c(
                            "div",
                            { staticClass: "RuleListdetail" },
                            [
                              _c("el-input", {
                                staticClass: "inputWidth",
                                attrs: {
                                  size: "mini",
                                  type: "textarea",
                                  placeholder: "请输入文案",
                                  maxlength: "200",
                                  autosize: { minRows: 1, maxRows: 1 },
                                  disabled: _vm.disableOperate,
                                },
                                model: {
                                  value:
                                    _vm.couponOptions
                                      .inviteGiftRecordActivitiesTitle,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.couponOptions,
                                      "inviteGiftRecordActivitiesTitle",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "couponOptions.inviteGiftRecordActivitiesTitle",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "text" }, [
                            _vm._v(
                              '不填写则展示默认文案"快来试试手气! 点下就能领到手, 速抢!"'
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "活动海报", required: true } },
                        [
                          _c(
                            "div",
                            {},
                            [
                              _c("AccessoryUpload", {
                                attrs: {
                                  listType: "picture-card",
                                  size: "mini",
                                  title: "",
                                  limit: 1,
                                  fileList: _vm.fileList,
                                  noneBtnImg:
                                    _vm.couponOptions.posterImageUrl != "",
                                },
                                on: {
                                  getFileItems: _vm.getFileItems,
                                  delFileItems: _vm.deleteImg,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c("el-form-item", { attrs: { label: "退款设置:" } }, [
                        _c(
                          "div",
                          { staticClass: "RuleListdetail" },
                          [
                            _c(
                              "el-checkbox",
                              {
                                attrs: { disabled: _vm.disableOperate },
                                model: {
                                  value:
                                    _vm.couponOptions
                                      .isOrderRefundCouponInvalid,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.couponOptions,
                                      "isOrderRefundCouponInvalid",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "couponOptions.isOrderRefundCouponInvalid",
                                },
                              },
                              [_vm._v("订单退款时优惠券失效")]
                            ),
                            _c("span", { staticClass: "ml10" }, [
                              _vm._v(
                                "勾选后，若分享者的订单退款，所有通过该订单分享出来的优惠券将不可继续领取已领取未使用的优惠券也将失效。"
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            2
          ),
          _c("Dialog", {
            attrs: { options: _vm.dialogOptions },
            on: {
              "update:options": function ($event) {
                _vm.dialogOptions = $event
              },
              handleEvent: _vm.handleEvent,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }