var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "fightGroup-detail",
    },
    [
      _c("topOperatingButton", {
        attrs: {
          showAudit: false,
          isAddBtn: false,
          isExamineBtn: false,
          isAuditBillBtn: false,
          isSubmitAddBtn: false,
          id: "topOperatingButton",
        },
        on: { submitForm: _vm.save, getQuit: _vm.getQuit },
      }),
      _c(
        "div",
        { staticClass: "main" },
        [
          _c(
            "el-form",
            {
              ref: "formRef",
              attrs: {
                "label-width": "70px",
                model: _vm.form,
                rules: _vm.rules,
              },
            },
            [
              _c("ComCard", {
                attrs: { title: "基本信息" },
                scopedSlots: _vm._u([
                  {
                    key: "content",
                    fn: function () {
                      return [
                        _c("basicInfo", {
                          ref: "basicInfo",
                          staticClass: "content",
                          attrs: { form: _vm.form.fightGroupBody },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("ComCard", {
                attrs: { title: "参与活动的商品" },
                scopedSlots: _vm._u([
                  {
                    key: "content",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "goods content" },
                          [
                            _c("ETable", {
                              attrs: {
                                data: _vm.form,
                                activityType: "101",
                                tableColumnLabel: _vm.goodsTableLabel,
                              },
                              on: {
                                selectGood: _vm.selectGood,
                                tableRow: _vm.tableRow,
                                deleteGood: _vm.deleteGood,
                                search: _vm.eTableSearch,
                                pitchGoods: _vm.pitchGoods,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("ComCard", {
                attrs: { title: "更多配置" },
                scopedSlots: _vm._u([
                  {
                    key: "content",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "moreConfig content" },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "优惠叠加",
                                  "label-width": "100px",
                                },
                              },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    model: {
                                      value:
                                        _vm.form.fightGroupBody.isEnableCoupon,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form.fightGroupBody,
                                          "isEnableCoupon",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.fightGroupBody.isEnableCoupon",
                                    },
                                  },
                                  [_vm._v("允许使用优惠券 ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "成团有效期",
                                  "label-width": "100px",
                                  prop: "",
                                  rules:
                                    _vm.rules["fightGroupBody.expirationDate"],
                                },
                              },
                              [
                                _c("SelectDayTime", {
                                  attrs: {
                                    expirationDate:
                                      _vm.form.fightGroupBody.expirationDate,
                                  },
                                  on: { selectDay: _vm.selectDay },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                staticClass: "rangeShop",
                                attrs: {
                                  label: "适用门店",
                                  "label-width": "100px",
                                  prop: "fightGroupBody.usingInStoreType",
                                  rules:
                                    _vm.rules[
                                      "fightGroupBody.usingInStoreType"
                                    ],
                                },
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value:
                                        _vm.form.fightGroupBody
                                          .usingInStoreType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form.fightGroupBody,
                                          "usingInStoreType",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.fightGroupBody.usingInStoreType",
                                    },
                                  },
                                  _vm._l(
                                    _vm.dict.type.fill_shop_range_type,
                                    function (dict) {
                                      return _c(
                                        "el-radio",
                                        {
                                          key: dict.value,
                                          attrs: {
                                            label: dict.value,
                                            value: dict.value,
                                          },
                                        },
                                        [_vm._v(_vm._s(dict.label) + " ")]
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.form.fightGroupBody.usingInStoreType === "1" ||
                            _vm.form.fightGroupBody.usingInStoreType === "2"
                              ? _c(
                                  "div",
                                  { staticClass: "selectShop" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "shopBtn marB10" },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { size: "mini" },
                                            on: { click: _vm.openShopDialog },
                                          },
                                          [_vm._v("选择门店")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("comTable", {
                                      staticClass: "marB10",
                                      attrs: {
                                        columnLabel: _vm.shopTableLable,
                                        tableData: _vm.form.shopItems,
                                      },
                                      on: { delTableRow: _vm.delShopRow },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "el-form-item",
                              {
                                staticClass: "rangeTime",
                                attrs: {
                                  label: "适用时间",
                                  "label-width": "100px",
                                  prop: "fightGroupBody.usingInTimeType",
                                  rules:
                                    _vm.rules["fightGroupBody.usingInTimeType"],
                                },
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value:
                                        _vm.form.fightGroupBody.usingInTimeType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form.fightGroupBody,
                                          "usingInTimeType",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.fightGroupBody.usingInTimeType",
                                    },
                                  },
                                  _vm._l(
                                    _vm.dict.type.use_time_range_type,
                                    function (dict) {
                                      return _c(
                                        "el-radio",
                                        {
                                          key: dict.value,
                                          attrs: {
                                            label: dict.value,
                                            value: dict.value,
                                          },
                                        },
                                        [_vm._v(_vm._s(dict.label) + " ")]
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.form.fightGroupBody.usingInTimeType === "2"
                              ? _c(
                                  "div",
                                  { staticClass: "timeTable" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "btn x-f marB10" },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { size: "mini" },
                                            on: { click: _vm.openDateDialog },
                                          },
                                          [_vm._v("选择时间")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("comTable", {
                                      staticClass: "marB10",
                                      attrs: {
                                        columnLabel: _vm.timeTableLable,
                                        tableData: _vm.form.specialDateItems,
                                      },
                                      on: { delTableRow: _vm.delTimeRow },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "限购数",
                                  "label-width": "100px",
                                },
                              },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    model: {
                                      value:
                                        _vm.form.fightGroupBody
                                          .isEnablePurchaseLimit,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form.fightGroupBody,
                                          "isEnablePurchaseLimit",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.fightGroupBody.isEnablePurchaseLimit",
                                    },
                                  },
                                  [_vm._v("开启")]
                                ),
                              ],
                              1
                            ),
                            _vm.form.fightGroupBody.isEnablePurchaseLimit
                              ? _c(
                                  "el-form-item",
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        staticClass: "purchaseLimit x-f",
                                        model: {
                                          value:
                                            _vm.form.fightGroupBody.limitedType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.fightGroupBody,
                                              "limitedType",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.fightGroupBody.limitedType",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "x-f" },
                                          [
                                            _c(
                                              "el-radio",
                                              { attrs: { label: 1 } },
                                              [_vm._v("活动限购数")]
                                            ),
                                            !(
                                              _vm.form.fightGroupBody
                                                .limitedType == "1"
                                            )
                                              ? _c(
                                                  "el-form-item",
                                                  [
                                                    _c("el-input", {
                                                      attrs: { disabled: "" },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.form.fightGroupBody
                                              .limitedType == "1"
                                              ? _c(
                                                  "el-form-item",
                                                  [
                                                    _c("el-input", {
                                                      attrs: {
                                                        onkeyup:
                                                          "this.value= this.value.match(/\\d+(\\.\\d{0,2})?/) ? this.value.match(/\\d+(\\.\\d{0,2})?/)[0] : ''",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form
                                                            .fightGroupBody
                                                            .activityLimitedQty,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form
                                                              .fightGroupBody,
                                                            "activityLimitedQty",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.fightGroupBody.activityLimitedQty",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "x-f" },
                                          [
                                            _c(
                                              "el-radio",
                                              { attrs: { label: 2 } },
                                              [_vm._v("每日限购数")]
                                            ),
                                            !(
                                              _vm.form.fightGroupBody
                                                .limitedType == "2"
                                            )
                                              ? _c(
                                                  "el-form-item",
                                                  [
                                                    _c("el-input", {
                                                      attrs: { disabled: "" },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.form.fightGroupBody
                                              .limitedType == "2"
                                              ? _c(
                                                  "el-form-item",
                                                  [
                                                    _c("el-input", {
                                                      attrs: {
                                                        onkeyup:
                                                          "this.value= this.value.match(/\\d+(\\.\\d{0,2})?/) ? this.value.match(/\\d+(\\.\\d{0,2})?/)[0] : ''",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form
                                                            .fightGroupBody
                                                            .dailyLimitedQty,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form
                                                              .fightGroupBody,
                                                            "dailyLimitedQty",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.fightGroupBody.dailyLimitedQty",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c("div", { staticClass: "tag" }, [
                                      _vm._v(
                                        " 活动限购数:当限购数为1时,所有活动商品每个买家只能购买1个商品:适用于低价抢购回馈客户的,限制每个客户只能抢购1个便宜的商品. "
                                      ),
                                    ]),
                                    _c("div", { staticClass: "tag" }, [
                                      _vm._v(
                                        " 每日限购数:当限购数为1时,所有活动商品每个买家每天只能购买1个商品:第2天可以再次购买1个,适用于低价吸引顾客复购,增加商城活跃度. "
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "预热",
                                  "label-width": "100px",
                                },
                              },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    model: {
                                      value:
                                        _vm.form.fightGroupBody.isEnablePreHeat,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form.fightGroupBody,
                                          "isEnablePreHeat",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.fightGroupBody.isEnablePreHeat",
                                    },
                                  },
                                  [_vm._v("开启")]
                                ),
                              ],
                              1
                            ),
                            _vm.form.fightGroupBody.isEnablePreHeat
                              ? _c(
                                  "el-form-item",
                                  { staticClass: "preheat" },
                                  [
                                    _c("div", { staticClass: "tag" }, [
                                      _vm._v(
                                        " 开启后,活动会提前展示;例如活动在18:00开始,提前6个小时展示,秒杀活动则会在12:00展示在小程序端 "
                                      ),
                                    ]),
                                    _vm._v(" 提前 "),
                                    _c("el-input", {
                                      attrs: {
                                        onkeyup:
                                          "this.value= this.value.match(/\\d+(\\.\\d{0,2})?/) ? this.value.match(/\\d+(\\.\\d{0,2})?/)[0] : ''",
                                      },
                                      model: {
                                        value:
                                          _vm.form.fightGroupBody.beforeQtyHour,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.fightGroupBody,
                                            "beforeQtyHour",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.fightGroupBody.beforeQtyHour",
                                      },
                                    }),
                                    _vm._v(" 小时展示 "),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "每日库存更新",
                                  "label-width": "100px",
                                },
                              },
                              [
                                _c("el-switch", {
                                  model: {
                                    value:
                                      _vm.form.fightGroupBody.dailyStockUpdates,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form.fightGroupBody,
                                        "dailyStockUpdates",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.fightGroupBody.dailyStockUpdates",
                                  },
                                }),
                                _c("div", { staticClass: "tag" }, [
                                  _vm._v(
                                    " 开启后,促销库存每天00:00会初始化为初始库存;例:促销库存为100件,今日售卖50件,剩余库存为50件;0点后,剩余库存会初始化为100件; "
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.loading
        ? _c("SelectGoods", {
            attrs: {
              queryCode: _vm.queryCode,
              isShopp: true,
              OpenGoods: _vm.showGoodsDia,
            },
            on: {
              "update:OpenGoods": function ($event) {
                _vm.showGoodsDia = $event
              },
              "update:open-goods": function ($event) {
                _vm.showGoodsDia = $event
              },
              getGoodsIds: _vm.pitchGoods,
            },
          })
        : _vm._e(),
      !_vm.loading
        ? _c("SelectShop", {
            attrs: { showShopDialog: _vm.showShopDialog },
            on: {
              getShop: _vm.selectShop,
              close: function ($event) {
                _vm.showShopDialog = false
              },
            },
          })
        : _vm._e(),
      _c("selectDate", {
        ref: "selectDate",
        attrs: { OpenDate: _vm.showDateDialog },
        on: {
          "update:OpenDate": function ($event) {
            _vm.showDateDialog = $event
          },
          "update:open-date": function ($event) {
            _vm.showDateDialog = $event
          },
          changeDate: _vm.changeDate,
        },
      }),
      _c("Dialog", {
        ref: "tablePage",
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }