var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "wrap",
      staticClass: "wrap",
      attrs: { id: "wrap" },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.couponOptions,
            rules: _vm.rules,
            "label-width": "130px",
          },
        },
        [
          _c("topOperatingButton", {
            ref: "topOperatingButton",
            attrs: {
              showAudit: _vm.isShowAudit,
              isStopBtn: false,
              isSubmitAddBtn: false,
              isAuditBillBtn: false,
              isExamineBtn: true,
              disabled: _vm.disableOperate,
              id: "topOperatingButton",
            },
            on: {
              submitForm: function ($event) {
                return _vm.submitForm(false)
              },
              addBill: function ($event) {
                return _vm.submitForm(true)
              },
              auditBill: _vm.auditForm,
              getQuit: function ($event) {
                return _vm.$router.go(-1)
              },
            },
          }),
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "基本信息", id: "basicInformation" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "x-f marT10" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "活动编号", prop: "billNo" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            size: "mini",
                            disabled: true,
                            placeholder: "活动编号",
                          },
                          model: {
                            value: _vm.couponOptions.billNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.couponOptions, "billNo", $$v)
                            },
                            expression: "couponOptions.billNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "活动名称", prop: "billName" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            size: "mini",
                            disabled: _vm.disableOperate,
                            placeholder: "活动名称",
                            maxlength: "40",
                          },
                          model: {
                            value: _vm.couponOptions.billName,
                            callback: function ($$v) {
                              _vm.$set(_vm.couponOptions, "billName", $$v)
                            },
                            expression: "couponOptions.billName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "活动日期", prop: "datetime" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            size: "mini",
                            type: "datetimerange",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            "default-time": ["00:00:00", "23:59:59"],
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                            disabled: _vm.disableOperate,
                          },
                          model: {
                            value: _vm.couponOptions.datetime,
                            callback: function ($$v) {
                              _vm.$set(_vm.couponOptions, "datetime", $$v)
                            },
                            expression: "couponOptions.datetime",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "方案说明", prop: "billRemark" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            size: "mini",
                            type: "textarea",
                            placeholder: "方案说明长度介于 1 和 200 字符之间",
                            maxlength: "200",
                            autosize: { minRows: 1, maxRows: 1 },
                            disabled: _vm.disableOperate,
                          },
                          model: {
                            value: _vm.couponOptions.billRemark,
                            callback: function ($$v) {
                              _vm.$set(_vm.couponOptions, "billRemark", $$v)
                            },
                            expression: "couponOptions.billRemark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "活动优惠券", id: "basicInformation" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: " marT10 mar20" },
                  [
                    _c(
                      "div",
                      { staticClass: "mt20" },
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 1 },
                            on: { input: _vm.radioInpit },
                            model: {
                              value: _vm.couponOptions.caseCarveUpType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.couponOptions,
                                  "caseCarveUpType",
                                  $$v
                                )
                              },
                              expression: "couponOptions.caseCarveUpType",
                            },
                          },
                          [_vm._v("好友均分")]
                        ),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 2 },
                            on: { input: _vm.radioInpit },
                            model: {
                              value: _vm.couponOptions.caseCarveUpType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.couponOptions,
                                  "caseCarveUpType",
                                  $$v
                                )
                              },
                              expression: "couponOptions.caseCarveUpType",
                            },
                          },
                          [_vm._v("拼手气")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "mb10",
                        attrs: { disabled: _vm.disableOperate, size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEvent("openCouponDialog")
                          },
                        },
                      },
                      [_vm._v("选择优惠券")]
                    ),
                    _c("EditTable", {
                      staticStyle: { width: "100%" },
                      attrs: { options: _vm.couponOptions },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "活动设置" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c("div", { staticClass: "giveRule" }, [
                  _c(
                    "div",
                    { staticClass: "multiplyTitle" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "活动可发起总量", required: true } },
                        [
                          _c(
                            "div",
                            { staticClass: "RuleListdetail" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "", prop: "caseUsableTotal" },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "fillRuleinputMoney",
                                    attrs: {
                                      disabled: _vm.disableOperate,
                                      size: "mini",
                                      oninput:
                                        "if (value > 99999) value = 99999; value=value.replace(/^0|[^0-9]/g,'')",
                                      max: 9999999,
                                    },
                                    model: {
                                      value: _vm.couponOptions.caseUsableTotal,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.couponOptions,
                                          "caseUsableTotal",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "couponOptions.caseUsableTotal",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "助力数量", prop: "limitHelpQty" } },
                        [
                          _c(
                            "div",
                            { staticClass: "RuleListdetail" },
                            [
                              _c("el-input", {
                                staticClass: "fillRuleinputMoney",
                                attrs: {
                                  disabled: _vm.disableOperate,
                                  size: "mini",
                                  oninput:
                                    "if (value > 10) value = 10; value=value.replace(/^0|[^0-9]/g,'')",
                                  max: 9999999,
                                },
                                model: {
                                  value: _vm.couponOptions.limitHelpQty,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.couponOptions,
                                      "limitHelpQty",
                                      $$v
                                    )
                                  },
                                  expression: "couponOptions.limitHelpQty",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "box",
                          attrs: { label: "瓜分有效期", prop: "limitHelpHour" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "RuleListdetail" },
                            [
                              _c(
                                "el-input",
                                {
                                  staticClass: "fillRuleinputMoney",
                                  staticStyle: { width: "200px" },
                                  attrs: {
                                    disabled: _vm.disableOperate,
                                    oninput:
                                      "if (value > 99999) value = 99999; value=value.replace(/^0|[^0-9]/g,'')",
                                  },
                                  model: {
                                    value: _vm.couponOptions.limitHelpHour,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.couponOptions,
                                        "limitHelpHour",
                                        $$v
                                      )
                                    },
                                    expression: "couponOptions.limitHelpHour",
                                  },
                                },
                                [
                                  _c(
                                    "template",
                                    { slot: "append" },
                                    [_c("el-button", [_vm._v("小时")])],
                                    1
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "发起限制", required: true } },
                        [
                          _c(
                            "div",
                            { staticClass: "RuleListdetail" },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: {
                                    disabled: _vm.disableOperate,
                                    label: 0,
                                  },
                                  model: {
                                    value: _vm.couponOptions.startTimesType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.couponOptions,
                                        "startTimesType",
                                        $$v
                                      )
                                    },
                                    expression: "couponOptions.startTimesType",
                                  },
                                },
                                [_vm._v("不限制")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c("el-form-item", { attrs: { label: "" } }, [
                        _c(
                          "div",
                          { staticClass: "RuleListdetail" },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: {
                                  disabled: _vm.disableOperate,
                                  label: 1,
                                },
                                model: {
                                  value: _vm.couponOptions.startTimesType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.couponOptions,
                                      "startTimesType",
                                      $$v
                                    )
                                  },
                                  expression: "couponOptions.startTimesType",
                                },
                              },
                              [_vm._v("每人活动期间最多可发起")]
                            ),
                            [1].includes(_vm.couponOptions.startTimesType)
                              ? _c(
                                  "div",
                                  { staticClass: "x-f" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "",
                                          prop: "everyOneTotalStartTimes",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-input",
                                          {
                                            staticClass: "fillRuleinputMoney",
                                            staticStyle: { width: "200px" },
                                            attrs: {
                                              disabled: _vm.disableOperate,
                                              size: "mini",
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.limitInputlength(
                                                  _vm.couponOptions
                                                    .everyOneTotalStartTimes,
                                                  _vm.couponOptions,
                                                  "everyOneTotalStartTimes",
                                                  true,
                                                  2,
                                                  false
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.couponOptions
                                                  .everyOneTotalStartTimes,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.couponOptions,
                                                  "everyOneTotalStartTimes",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "couponOptions.everyOneTotalStartTimes",
                                            },
                                          },
                                          [
                                            _c(
                                              "template",
                                              { slot: "append" },
                                              [_c("el-button", [_vm._v("次")])],
                                              1
                                            ),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                      _c("el-form-item", { attrs: { label: "" } }, [
                        _c(
                          "div",
                          { staticClass: "RuleListdetail" },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: {
                                  disabled: _vm.disableOperate,
                                  label: 2,
                                },
                                model: {
                                  value: _vm.couponOptions.startTimesType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.couponOptions,
                                      "startTimesType",
                                      $$v
                                    )
                                  },
                                  expression: "couponOptions.startTimesType",
                                },
                              },
                              [_vm._v("每人每天最多可发起")]
                            ),
                            [2].includes(_vm.couponOptions.startTimesType)
                              ? _c(
                                  "div",
                                  { staticClass: "x-f" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "",
                                          prop: "everyOneDayStartTimes",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-input",
                                          {
                                            staticClass: "fillRuleinputMoney",
                                            staticStyle: { width: "200px" },
                                            attrs: {
                                              disabled: _vm.disableOperate,
                                              size: "mini",
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.limitInputlength(
                                                  _vm.couponOptions
                                                    .everyOneDayStartTimes,
                                                  _vm.couponOptions,
                                                  "everyOneDayStartTimes",
                                                  true,
                                                  2,
                                                  false
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.couponOptions
                                                  .everyOneDayStartTimes,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.couponOptions,
                                                  "everyOneDayStartTimes",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "couponOptions.everyOneDayStartTimes",
                                            },
                                          },
                                          [
                                            _c(
                                              "template",
                                              { slot: "append" },
                                              [_c("el-button", [_vm._v("次")])],
                                              1
                                            ),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { label: "助力限制", required: true } },
                        [
                          _c(
                            "div",
                            { staticClass: "RuleListdetail" },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: {
                                    disabled: _vm.disableOperate,
                                    label: 0,
                                  },
                                  model: {
                                    value: _vm.couponOptions.helpTimesType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.couponOptions,
                                        "helpTimesType",
                                        $$v
                                      )
                                    },
                                    expression: "couponOptions.helpTimesType",
                                  },
                                },
                                [_vm._v("不限制")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c("el-form-item", { attrs: { label: "" } }, [
                        _c(
                          "div",
                          { staticClass: "RuleListdetail" },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: {
                                  disabled: _vm.disableOperate,
                                  label: 1,
                                },
                                model: {
                                  value: _vm.couponOptions.helpTimesType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.couponOptions,
                                      "helpTimesType",
                                      $$v
                                    )
                                  },
                                  expression: "couponOptions.helpTimesType",
                                },
                              },
                              [_vm._v("每人活动期间最多可助力")]
                            ),
                            [1].includes(_vm.couponOptions.helpTimesType)
                              ? _c(
                                  "div",
                                  { staticClass: "x-f" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "",
                                          prop: "everyOneTotalHelpTimes",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-input",
                                          {
                                            staticClass: "fillRuleinputMoney",
                                            staticStyle: { width: "200px" },
                                            attrs: {
                                              disabled: _vm.disableOperate,
                                              size: "mini",
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.limitInputlength(
                                                  _vm.couponOptions
                                                    .everyOneTotalHelpTimes,
                                                  _vm.couponOptions,
                                                  "everyOneTotalHelpTimes",
                                                  true,
                                                  2,
                                                  false
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.couponOptions
                                                  .everyOneTotalHelpTimes,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.couponOptions,
                                                  "everyOneTotalHelpTimes",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "couponOptions.everyOneTotalHelpTimes",
                                            },
                                          },
                                          [
                                            _c(
                                              "template",
                                              { slot: "append" },
                                              [_c("el-button", [_vm._v("次")])],
                                              1
                                            ),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                      _c("el-form-item", { attrs: { label: "", prop: "" } }, [
                        _c(
                          "div",
                          { staticClass: "RuleListdetail" },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: {
                                  disabled: _vm.disableOperate,
                                  label: 2,
                                },
                                model: {
                                  value: _vm.couponOptions.helpTimesType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.couponOptions,
                                      "helpTimesType",
                                      $$v
                                    )
                                  },
                                  expression: "couponOptions.helpTimesType",
                                },
                              },
                              [_vm._v("每人每天最多可助力")]
                            ),
                            [2].includes(_vm.couponOptions.helpTimesType)
                              ? _c(
                                  "div",
                                  { staticClass: "x-f" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "",
                                          prop: "everyOneDayHelpTimes",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-input",
                                          {
                                            staticClass: "fillRuleinputMoney",
                                            staticStyle: { width: "200px" },
                                            attrs: {
                                              disabled: _vm.disableOperate,
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.limitInputlength(
                                                  _vm.couponOptions
                                                    .everyOneDayHelpTimes,
                                                  _vm.couponOptions,
                                                  "everyOneDayHelpTimes",
                                                  true,
                                                  2,
                                                  false
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.couponOptions
                                                  .everyOneDayHelpTimes,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.couponOptions,
                                                  "everyOneDayHelpTimes",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "couponOptions.everyOneDayHelpTimes",
                                            },
                                          },
                                          [
                                            _c(
                                              "template",
                                              { slot: "append" },
                                              [_c("el-button", [_vm._v("次")])],
                                              1
                                            ),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { label: "活动分享卡设置", required: true } },
                        [
                          _c(
                            "div",
                            {},
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: {
                                    disabled: _vm.disableOperate,
                                    label: 1,
                                  },
                                  model: {
                                    value: _vm.couponOptions.shareCardType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.couponOptions,
                                        "shareCardType",
                                        $$v
                                      )
                                    },
                                    expression: "couponOptions.shareCardType",
                                  },
                                },
                                [_vm._v("默认分享卡")]
                              ),
                              _c("div", [
                                _c("img", {
                                  staticStyle: {
                                    "vertical-align": "text-top",
                                    width: "100px",
                                    height: "100px",
                                  },
                                  attrs: { src: _vm.shareUrL, alt: "加载失败" },
                                }),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {},
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: {
                                    disabled: _vm.disableOperate,
                                    label: 2,
                                  },
                                  model: {
                                    value: _vm.couponOptions.shareCardType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.couponOptions,
                                        "shareCardType",
                                        $$v
                                      )
                                    },
                                    expression: "couponOptions.shareCardType",
                                  },
                                },
                                [_vm._v("自定义分享卡")]
                              ),
                              _c("AccessoryUpload", {
                                attrs: {
                                  listType: "picture-card",
                                  size: "mini",
                                  title: "",
                                  limit: 1,
                                  fileList: _vm.fileList,
                                  dialogImageUrl:
                                    _vm.couponOptions.posterImageUrl,
                                  noneBtnImg:
                                    _vm.couponOptions.posterImageUrl != "",
                                },
                                on: {
                                  getFileItems: _vm.getFileItems,
                                  delFileItems: _vm.deleteImg,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {},
                            [
                              _c("el-input", {
                                staticClass: "inputWidth",
                                attrs: {
                                  size: "mini",
                                  type: "textarea",
                                  placeholder: "请输入文案",
                                  maxlength: "200",
                                  autosize: { minRows: 1, maxRows: 1 },
                                  disabled: _vm.disableOperate,
                                },
                                model: {
                                  value:
                                    _vm.couponOptions
                                      .shareCardRecordActivitiesTitle,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.couponOptions,
                                      "shareCardRecordActivitiesTitle",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "couponOptions.shareCardRecordActivitiesTitle",
                                },
                              }),
                              _c("span", [
                                _vm._v(
                                  "专属优惠券等你拿，邀请好友加入，一起分享喜悦"
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "模拟好友:", prop: "" } },
                        [
                          _c(
                            "div",
                            { staticClass: "RuleListdetail" },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  attrs: { disabled: _vm.disableOperate },
                                  model: {
                                    value: _vm.couponOptions.isUseImitateHelp,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.couponOptions,
                                        "isUseImitateHelp",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "couponOptions.isUseImitateHelp",
                                  },
                                },
                                [_vm._v("开启模拟好友，瓜分优惠")]
                              ),
                              _c(
                                "el-tooltip",
                                {
                                  staticStyle: {
                                    color: "black",
                                    "margin-left": "10px",
                                  },
                                  attrs: {
                                    content:
                                      "设置的瓜分有效期内未达成瓜分条件，系统将模拟“匿名好友”瓜分优惠，提高优惠券领取和使用率，建议开启。",
                                    placement: "top",
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-question" })]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            2
          ),
          _c("Dialog", {
            attrs: { options: _vm.dialogOptions },
            on: {
              "update:options": function ($event) {
                _vm.dialogOptions = $event
              },
              handleEvent: _vm.handleEvent,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }