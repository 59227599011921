<template>
  <!-- 线上商城营销商品表格 Error: please transfer a valid prop path to form item! -->
  <div class="table">
    <!-- <el-form :model="data" :rules="rules" ref="tabListRef">-->
    <el-table
      class="table"
      ref="Table"
      :data="data.goodsItems"
      row-key="columnId"
      max-height="300"
      border
    >
      <el-table-column align="center" width="">
        <template v-slot="scope">
          <i
            @click="handleRow('push', scope.$index)"
            class="el-icon-circle-plus operatePush"
          ></i>
          <i
            @click="handleRow('del', scope.$index)"
            class="el-icon-remove operateDel"
          ></i>
          <!--                        <div v-if="!scope.row.hoverRow">{{ scope.$index + 1 }}</div>-->
        </template>
      </el-table-column>
      <el-table-column label="商品编码" width="260" align="center" prop="goodsNo">
        <template slot="header">
          <i style="color: #ff4949">*</i>
          商品编码
        </template>
        <template slot-scope="scope">
          <el-form-item
            label-width="0"
            :prop="`goodsItems[${scope.$index}].goodsNo`"
            :rules="rules[`goodsItems.goodsNo`]"
          >
            <!-- <el-input
              v-model="scope.row.goodsNo"
              @keyup.enter.native="toFilterData(scope.row.goodsNo, scope.$index)"
            >
              <i slot="suffix" @click="selectGood" class="el-icon-more more"></i>

            </el-input> -->
            <SelectRemote
              size="mini"
              v-model="scope.row.goodsNo"
              :option="
                $select({
                  key: 'o2oGoods',
                  option: {
                    option: {
                      noEcho: true,
                      value: 'goodsNo',
                      showItem: data.goodsItems,
                      change: (propValue, obj) => {
                        $emit('pitchGoods', [obj], scope.$index);
                      },
                      tableChange: (propValue, obj) => {
                        $emit('pitchGoods', obj.check);
                      },
                      buttons: [
                        {
                          type: 'more',
                          option: {
                            title: '选择商品',
                            width: 1250,
                            type: 'TreeAndTable',
                            formData: $dialog({ key: 'o2oGoods' }),
                          },
                        },
                      ],
                    },
                  },
                }).option
              "
              :filterable="true"
              placeholder="选择商品"
            />
          </el-form-item>
        </template>
      </el-table-column>
      <!-- 每一行有edit属性为true可编辑表格列 -->
      <el-table-column
        v-for="(item, index) in tableColumnLabel"
        :key="index"
        :label="item.label"
        align="center"
        :prop="item.prop"
      >
        <template slot="header" v-if="item.edit && item.prop != 'fightGroupHeadPrice'">
          <i style="color: #ff4949">*</i>
          {{ item.label }}
        </template>
        <template slot-scope="scope">
          <div v-if="!item.edit">{{ scope.row[item.prop] }}</div>
          <el-form-item
            v-else
            :prop="`goodsItems[${scope.$index}][${item.prop}]`"
            :rules="rules[`goodsItems.${item.prop}`]"
            label-width="0"
          >
            <el-input-number
              v-if="item.prop != 'unitId'"
              style="width: 100px; margin: 0 5px"
              v-model="scope.row[item.prop]"
              :precision="item.precision"
              :controls="false"
              :min="item.min"
              @blur="checkoutFn(scope.row, item.prop)"
            />
            <!-- <el-input
              v-model.number="scope.row[item.prop]"
              autocomplete="off"
              @blur="checkoutFn(scope.row, item.prop)"
            ></el-input> -->
            <el-select
              v-model="scope.row[item.prop]"
              placeholder="请选择"
              v-else
              disabled
              @change="unitSaleBarcodeChange($event, scope.row)"
            >
              <el-option
                v-for="item in scope.row.units"
                :key="item.unitId"
                :label="item.unitName"
                :value="item.unitId"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
    </el-table>
    <!--        </el-form>-->
  </div>
</template>

<script>
import SelectRemote from "@/components/tablePage/select/select-remote/index.vue";

import { groupVerificationActivityGoods } from "@/api/O2OMall/marketing/fightGroup";
import {
  seckillVerificationActivityGoods,
  bargainActivityGoods,
} from "@/api/O2OMall/marketing/seckill";
import { listActivityGoodsOption } from "@/api/O2OMall/material/list";

export default {
  name: "ETable",
  components: { SelectRemote },
  props: {
    //表格数据
    data: {
      type: Object,
      default: {},
    },
    //表格列字段
    tableColumnLabel: {
      type: Array,
      default: () => [],
    },
    activityType: {
      type: String,
    },
  },
  created() {
    console.log("初始：", this.data);
  },
  data() {
    return {
      options: [],
      value: "",
      rules: {
        "goodsItems.goodsNo": [
          { required: true, message: "请选择商品", trigger: ["change"] },
        ],
        "goodsItems.unitId": [
          { required: true, message: "请选择单位", trigger: ["blur", "change"] },
        ],
        "goodsItems.fightGroupPrice": [
          {
            required: true,
            message: "请输入团购价",
            trigger: ["blur", "change"],
          },
          {
            validator: (rule, value, callback) => {
              if (Number(value) < 0.01) {
                callback(new Error("请输入团购价"));
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"],
          },
        ],
        // 'goodsItems.fightGroupHeadPrice': [
        //     { required: true, message: '请输入团长价', trigger: ['blur', 'change'] }
        // ],
        "goodsItems.activityStockQuantity": [
          {
            required: true,
            message: "请输入活动库存",
            trigger: ["blur", "change"],
          },
          {
            validator: (rule, value, callback) => {
              if (Number(value) < 1) {
                callback(new Error("请输入团购价"));
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"],
          },
        ],
        "goodsItems.minPurchaseQuantity": [
          {
            required: true,
            message: "请输入起购数",
            trigger: ["blur", "change"],
          },
          {
            validator: (rule, value, callback) => {
              if (Number(value) < 1) {
                callback(new Error("请输入团购价"));
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"],
          },
        ],
        "goodsItems.groupSize": [
          {
            required: true,
            message: "请输入成团人数",
            trigger: ["blur", "change"],
          },
          {
            validator: (rule, value, callback) => {
              if (Number(value) < 2) {
                callback(new Error("最低成团人数2人"));
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"],
          },
        ],
        "goodsItems.seckillPrice": [
          {
            required: true,
            message: "请输入秒杀价",
            trigger: ["blur", "change"],
          },
          {
            validator: (rule, value, callback) => {
              if (Number(value) < 0.01) {
                callback(new Error("请输入秒杀价"));
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    // 校验商品
    checkoutFn(item, prop) {
      // console.log('商品ID校验', item.goodsId, prop)
      if (prop == "minPurchaseQuantity") {
        if (this.activityType == 101) {
          // 拼团
          groupVerificationActivityGoods({
            goodsId: item.goodsId,
          }).then((res) => {
            if (!res.data) {
              this.$message.warning("此商品已被选为其他活动，不可重复添加");
              this.$emit("deleteGood", item.goodsId);
            }
          });
        } else if (this.activityType == 102) {
          // 秒杀
          seckillVerificationActivityGoods({
            goodsId: item.goodsId,
          }).then((res) => {
            if (!res.data) {
              this.$message.warning("此商品已被选为其他活动，不可重复添加");
              this.$emit("deleteGood", item.goodsId);
            }
          });
        } else if (this.activityType == 103) {
          // 秒杀
          bargainActivityGoods({
            goodsId: item.goodsId,
          }).then((res) => {
            if (!res.data) {
              this.$message.warning("此商品已被选为其他活动，不可重复添加");
              this.$emit("deleteGood", item.goodsId);
            }
          });
        }
      }
    },
    // 回车搜索
    toFilterData(goodsNo, index) {
      const loading = this.$loading({
        lock: true,
        text: "提交中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.0)",
      });
      listActivityGoodsOption({
        pageNum: 1,
        pageSize: 15,
        queryCode: goodsNo,
        goodsTypes: "1",
        status: 0,
      })
        .then((response) => {
          this.$emit("search", { ...response, goodsNo }, index);
        })
        .finally(() => {
          try {
            loading.close();
          } catch (error) {}
        });
    },
    // 多单位修改条码、价格
    unitSaleBarcodeChange(value, row) {
      row.units.forEach((v) => {
        if (v.unitId === value) {
          this.$set(row, "barcode", v.barcode);
          this.$set(row, "salePrice", v.salePrice);
          // const unitMoney = row.unitQty * row.unitPrice
          // this.$set(row, 'unitMoney', unitMoney)
        }
      });
    },
    //表格增加一行/减少一行
    handleRow(type, index) {
      this.$emit("tableRow", { type: type, index: index });
    },
    // 选择商品
    selectGood() {
      console.log("777777");
      this.$emit("selectGood");
    },
    //表格单元格回车事件
    keyup(name, event, index) {
      //判断是否输入编码或条码
      /* if (this.form.details[`${index}`][`${name}`]) {
              listGoods({
                [name]: this.form.details[`${index}`][`${name}`]
              }).then(async response => {
                // 判断是否精准匹配 匹配直接赋值 不匹配弹出商品框
                if (response.total === 1) {
                  event.target.blur()
                  //使用vue的$set方法更新数组数据,使视图可编辑
                  response.rows[0].unitPrice = response.rows[0].salePrice
                  this.$set(this.form.details, index, response.rows[0])
                  this.iskey = !this.iskey
                  this.form.details = this.form.details.filter(
                    item =>
                      (!(JSON.stringify(item) === '{}') &&
                        !(item.goodsId === undefined)) ||
                      !(item.goodsId === undefined)
                  )
                  if (!bool && this.TypeBill === '107') {
                    //需要获取商品当前库存数进入
                    getGoodsFinalQty({
                      goodsIds: [response.rows[0].goodsId],
                      storeId: this.form.storeId
                    }).then(response => {
                      this.$set(
                        this.form.details[index],
                        'finalQty',
                        response.data.goodsQtyList[0].finalQty
                      )
                    })
                  }

                } else {
                  this.queryCode = this.form.details[`${index}`][`${name}`]
                  this.OpenGoods = true
                }
              })
            } else {
              //没输入直接弹出商品信息框
              event.target.blur()
              this.OpenGoods = true
            } */
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 0;
}

//使表格校验时显示，校验通过时隐藏
::v-deep .el-form-item {
  padding: 0;
  margin: 0;
}

::v-deep .el-form-item__error {
  position: static;
}
</style>
