<!-- 领券中心 -->
<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import TablePage from "@/components/tablePage";
import {
  drawCouponList, //分页列表
  delDrawCoupon, //删除
  isStopDrawCoupon, //启用/禁用
  copyDrawCoupon, //复制
  carefulDrawCoupon, //审核
} from "@/api/O2OMall/marketing/drawCouponCenter"; //接口api
export default {
  name: "drawCouponCenter",
  components: { TablePage },
  dicts: ["bill_status"],
  data() {
    return {
      options: {
        mutiSelect: true, // 多选
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        labelWidth: "100",
        getListApi: drawCouponList,
        body: {},
        listNo: true, // 序号
        title: "拼团",
        check: [], // 选中数据
        rowKey: "billId",
        search: [
          {
            label: '方案日期',
            type: 'datetimerange',
            model: '',
            noReset: true,
            filterFnc: ([a, b] = []) => ({ begTime: a, endTime: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'month',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          // { type: "input", label: '', tip:"全部/活动编号/活动名称", model: "", filter: "query" },
          {
            type: 'filters',
            tip: '全部/活动编号/活动名称',
            isRestore: true,
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'billNos', label: '活动编号' },
              { filter: 'billNames', label: '活动名称' }
            ]
          },
          {
            type: 'local',
            label: '启用状态',
            model: '',
            filter: 'isStops',
            seniorSearch: true,
            option: {
              data: [
              { label: '启用', value: '0' },
              { label: '禁用', value: '1' }
            ],
              label: 'label',
              value: 'value',
              multiple: true
            }
          },
          this.$select({
            key: 'bill_status',
            option: {
              label: '方案状态',
              seniorSearch: true,
              option: { multiple: true }
            }
          }),
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        buttons: [
          {
            click: "add",
            label: "新增",
            icon: "el-icon-plus",
            type: "primary",
            disabled: false,
          },
          {
            click: "audit",
            label: "审核",
            type: "success",
            btnType: "dropdown",
            other: [
              {
                click: "redoAudit",
                label: "反审核",
              },
            ],
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "enable",
            label: "启用",
            type: "warning",
            btnType: "dropdown",
            other: [
              {
                click: "redoEnable",
                label: "禁用",
              },
            ],
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "copy",
            label: "复制",
            icon: "el-icon-document-copy",
            type: "success",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "del",
            label: "删除",
            type: "danger",
            icon: "el-icon-delete",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        columns: [
          {
            prop: "billNo",
            label: "活动方案编号",
            type: "link",
            click: "routerLink",
            minWidth: 200,
          },
          {
            prop: "billName",
            label: "活动方案名称",
            minWidth: 160,
          },
          {
            prop: "couponStatus",
            label: "执行状态",
            minWidth: 120,
          },
          {
            prop: "billStatus",
            label: "审核状态",
            type: "dict",
            minWidth: 120,
            dict: "bill_status",
          },
          {
            prop: "begTime",
            label: "生效时间",
            minWidth: 160,
          },
          {
            prop: "endTime",
            label: "失效时间",
            minWidth: 160,
          },
          {
            prop: "isStop",
            label: "启用状态",
            minWidth: 100,
            formatter: (v) => (v ? "启用" : "禁用"),
          },
          {
            prop: "couponEmitQty",
            label: "投放张数",
            minWidth: 100,
          },
          {
            prop: "couponSumDrawQty",
            label: "已领张数",
            minWidth: 100,
          },
          {
            prop: "remark",
            label: "备注",
            minWidth: 100,
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 160,
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 160,
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 160,
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 160,
          },
        ],
        list: [],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      const selectData = this.options.check;
      switch (type) {
        case "routerLink":
          {
            this.$router.push(
              `/O2OMall/marketing/drawCouponCenterDetail?type=update&billId=${row.billId}`
            );
          }
          break;
        case "add":
          this.$router.push(`/O2OMall/marketing/drawCouponCenterDetail?type=add`);
          break;
        case "audit":
          {
            if (!selectData.length) return;
            this.handleBillStatus("审核", selectData);
          }
          break;
        case "redoAudit":
          {
            if (!selectData.length) return;
            this.handleBillStatus("反审核", selectData);
          }
          break;
        case "enable":
          {
            if (!selectData.length) return;
            this.handleBillStatus("启用", selectData);
          }
          break;
        case "redoEnable":
          {
            if (!selectData.length) return;
            this.handleBillStatus("禁用", selectData);
          }
          break;
        case "del":
          {
            if (!selectData.length) return;
            this.handleBillStatus("删除", selectData);
          }
          break;
        case "copy":
          {
            if (!selectData.length) return;
            this.handleBillStatus("复制", selectData);
          }
          break;
        default:
          break;
      }
    },
    seniorSearch() {},
    handleBillStatus(command, selectData, url) {
      let name = "";
      let billStatus = "";
      let isStop = "";
      if (command === "反审核") {
        name = "反审核";
        billStatus = "0";
      } else {
        if (command === "审核") {
          name = "审核";
          billStatus = "1";
        } else if (command === "删除") {
          name = "删除";
        } else if (command === "复制") {
          name = "复制";
        } else if (command === "启用") {
          name = "启用";
          isStop = true;
        } else if (command === "禁用") {
          name = "禁用";
          isStop = false;
        }
      }
      const billId = selectData.map((i) => i.billId);
      const $this = this;
      this.$modal.confirm(`是否确认${name}活动`).then(() => {
        if (["启用", "禁用"].includes(name)) {
          // isStopDrawCoupon({billIds: [...billId],isStop: isStop} ).then(res => {
          isStopDrawCoupon([...billId]).then((res) => {
            $this.$nextTick(() => {
              $this.options.check = [];
            });
            setTimeout(() => {
              $this.$refs.tablePage.getList();
            }, 500);
            if (isStop) {
              $this.$modal.msgSuccess("启用成功");
            } else {
              $this.$modal.msgSuccess("禁用成功");
            }
          });
        } else if (["复制", "删除"].includes(name)) {
          let url = name == "复制" ? copyDrawCoupon : delDrawCoupon;
          url(billId).then((res) => {
            setTimeout(() => {
              $this.$refs.tablePage.getList();
            }, 500);
            $this.$modal.msgSuccess("操作成功");
          });
        } else {
          // carefulDrawCoupon({billIds: [...billId], billStatus}).then(() => {
          carefulDrawCoupon([...billId]).then(() => {
            $this.$nextTick(() => {
              $this.options.check = [];
            });
            $this.$modal.msgSuccess(`${name}成功`);
            setTimeout(() => {
              $this.$refs.tablePage.getList();
            }, 500);
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
