var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table" },
    [
      _c(
        "el-table",
        {
          ref: "Table",
          staticClass: "table",
          attrs: {
            data: _vm.data.goodsItems,
            "row-key": "columnId",
            "max-height": "300",
            border: "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", width: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("i", {
                      staticClass: "el-icon-circle-plus operatePush",
                      on: {
                        click: function ($event) {
                          return _vm.handleRow("push", scope.$index)
                        },
                      },
                    }),
                    _c("i", {
                      staticClass: "el-icon-remove operateDel",
                      on: {
                        click: function ($event) {
                          return _vm.handleRow("del", scope.$index)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c(
            "el-table-column",
            {
              attrs: {
                label: "商品编码",
                width: "260",
                align: "center",
                prop: "goodsNo",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            "label-width": "0",
                            prop: "goodsItems[" + scope.$index + "].goodsNo",
                            rules: _vm.rules["goodsItems.goodsNo"],
                          },
                        },
                        [
                          _c("SelectRemote", {
                            attrs: {
                              size: "mini",
                              option: _vm.$select({
                                key: "o2oGoods",
                                option: {
                                  option: {
                                    noEcho: true,
                                    value: "goodsNo",
                                    showItem: _vm.data.goodsItems,
                                    change: function (propValue, obj) {
                                      _vm.$emit(
                                        "pitchGoods",
                                        [obj],
                                        scope.$index
                                      )
                                    },
                                    tableChange: function (propValue, obj) {
                                      _vm.$emit("pitchGoods", obj.check)
                                    },
                                    buttons: [
                                      {
                                        type: "more",
                                        option: {
                                          title: "选择商品",
                                          width: 1250,
                                          type: "TreeAndTable",
                                          formData: _vm.$dialog({
                                            key: "o2oGoods",
                                          }),
                                        },
                                      },
                                    ],
                                  },
                                },
                              }).option,
                              filterable: true,
                              placeholder: "选择商品",
                            },
                            model: {
                              value: scope.row.goodsNo,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "goodsNo", $$v)
                              },
                              expression: "scope.row.goodsNo",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c("template", { slot: "header" }, [
                _c("i", { staticStyle: { color: "#ff4949" } }, [_vm._v("*")]),
                _vm._v(" 商品编码 "),
              ]),
            ],
            2
          ),
          _vm._l(_vm.tableColumnLabel, function (item, index) {
            return _c(
              "el-table-column",
              {
                key: index,
                attrs: { label: item.label, align: "center", prop: item.prop },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          !item.edit
                            ? _c("div", [_vm._v(_vm._s(scope.row[item.prop]))])
                            : _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop:
                                      "goodsItems[" +
                                      scope.$index +
                                      "][" +
                                      item.prop +
                                      "]",
                                    rules: _vm.rules["goodsItems." + item.prop],
                                    "label-width": "0",
                                  },
                                },
                                [
                                  item.prop != "unitId"
                                    ? _c("el-input-number", {
                                        staticStyle: {
                                          width: "100px",
                                          margin: "0 5px",
                                        },
                                        attrs: {
                                          precision: item.precision,
                                          controls: false,
                                          min: item.min,
                                        },
                                        on: {
                                          blur: function ($event) {
                                            return _vm.checkoutFn(
                                              scope.row,
                                              item.prop
                                            )
                                          },
                                        },
                                        model: {
                                          value: scope.row[item.prop],
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, item.prop, $$v)
                                          },
                                          expression: "scope.row[item.prop]",
                                        },
                                      })
                                    : _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "请选择",
                                            disabled: "",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.unitSaleBarcodeChange(
                                                $event,
                                                scope.row
                                              )
                                            },
                                          },
                                          model: {
                                            value: scope.row[item.prop],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                item.prop,
                                                $$v
                                              )
                                            },
                                            expression: "scope.row[item.prop]",
                                          },
                                        },
                                        _vm._l(
                                          scope.row.units,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.unitId,
                                              attrs: {
                                                label: item.unitName,
                                                value: item.unitId,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                ],
                                1
                              ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              },
              [
                item.edit && item.prop != "fightGroupHeadPrice"
                  ? _c("template", { slot: "header" }, [
                      _c("i", { staticStyle: { color: "#ff4949" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(" " + _vm._s(item.label) + " "),
                    ])
                  : _vm._e(),
              ],
              2
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }